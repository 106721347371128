import Hero from "../components/Index/Hero";
import { RiGalleryFill } from "react-icons/ri";
import IMG1 from "../assets/ac-72x36-1.jpeg";
import IMG2 from "../assets/ac-60x36-3.jpeg";
import IMG3 from "../assets/ac-72x36-3.jpg";
import IMG4 from "../assets/ac-60x36-1.jpg";
import IMG5 from "../assets/ac-60x36-2.jpeg";
import IMG6 from "../assets/ac-60x36-4.jpg";
import IMG7 from "../assets/ac-96x48-1.jpeg";
import IMG8 from "../assets/ac-96x48-2.jpg";
import IMG9 from "../assets/ac-96x48-3.jpeg";
import IMG10 from "../assets/ac-60x36-5.jpg";
import IMG11 from "../assets/ac-60x36-6.jpg";
import IMG12 from "../assets/ac-84x60-1.jpg";
import IMG13 from "../assets/ac-48x36-1.jpg";
import IMG14 from "../assets/ac-48x36-2.jpg";
import IMG15 from "../assets/ac-48x36-3.jpeg";

import IMG16 from "../assets/ac-48x36-4.jpeg";
import IMG17 from "../assets/ac-48x36-5.jpg";
import IMG18 from "../assets/ac-48x48-1.jpeg";
import IMG19 from "../assets/ac-48x48-2.jpeg";
import IMG20 from "../assets/ac-48x48-3.jpg";
import IMG21 from "../assets/ac-46x36-1.jpeg";


import IMG22 from "../assets/ac-42x30-1.jpeg";
import IMG23 from "../assets/ac-42x30-2.jpg";
import IMG24 from "../assets/ac-42x30-3.jpg";
import IMG25 from "../assets/ac-36x48-1.jpg";


import IMG26 from "../assets/ac-34x28-1.jpeg";
import IMG27 from "../assets/ac-34x28-2.jpeg";
import IMG28 from "../assets/ac-30x20-1.jpg";
import IMG29 from "../assets/ac-36x36-1.jpg";
import IMG30 from "../assets/ac-30x30-1.jpeg";
import IMG31 from "../assets/ac-36x60-1.jpg";
import IMG32 from "../assets/ac-36x24-1.jpeg";
import IMG33 from "../assets/ac-24x36-1.jpeg";
import IMG34 from "../assets/ac-30x24-1.jpeg";

import IMG35 from "../assets/ac-84x60-2.jpg";
import IMG36 from "../assets/ac-96x48-4.jpg";
import IMG37 from "../assets/ac-120x72-1.jpg";
import IMG38 from "../assets/ac-36x24-3.jpeg";
import IMG39 from "../assets/ac-72x36-4.jpeg";

import GIF from "../assets/home.gif";
import Galleryimg from "../components/Index/Galleryimg";
import { Fragment } from "react";

const Index = () => {
  const ImgData = [
    {
      img: IMG1,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `72" x 36"inch`,
      sold: "SOLD",
    },

    {
      img: IMG2,
      title: "image title 3 ",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "",
    },
    {
      img: IMG3,
      title: "image title 1",
      painting: "Acrylic on Canvas",
      size: `72" x 36"inch`,
      sold: " SOLD",
    },
    {
      img: IMG4,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG5,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG6,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG7,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `96" x 48"inch`,
      sold: "",
    },
    {
      img: IMG8,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `96" x 48"inch`,
      sold: " SOLD",
    },
    {
      img: IMG9,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `96" x 48"inch`,
      sold: " SOLD",
    },

    {
      img: IMG10,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG11,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "",
    },

    {
      img: IMG12,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `84" x 60"inch`,
      sold: "",
    },

    {
      img: IMG13,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "",
    },

    {
      img: IMG14,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "",
    },

    {
      img: IMG15,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG16,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG17,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG18,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "",
    },

    {
      img: IMG19,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: " SOLD",
    },


    {
      img: IMG20,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG21,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: " ",
    },

    {
      img: IMG22,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `46" x 36"inch`,
      sold: "",
    },

    {
      img: IMG23,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `42" x 30"inch`,
      sold: " SOLD",
    },

    {
      img: IMG24,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `40" x 30"inch`,
      sold: " ",
    },

    {
      img: IMG25,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `36" x 48"inch`,
      sold: " SOLD",
    },

    {
      img: IMG26,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `34" x 28"inch`,
      sold: "SOLD",
    },

    {
      img: IMG27,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `34" x 28"inch`,
      sold: " ",
    },

    {
      img: IMG28,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `30" x 24"inch`,
      sold: "SOLD",
    },

    {
      img: IMG29,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `36" x 36"inch`,
      sold: " ",
    },

    {
      img: IMG30,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `30" x 30"inch`,
      sold: "",
    },

    {
      img: IMG31,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: " SOLD",
    },

    {
      img: IMG32,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `36" x 24"inch`,
      sold: " ",
    },

    {
      img: IMG33,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `36" x 24"inch`,
      sold: " SOLD",
    },

    {
      img: IMG34,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `30" x 24"inch`,
      sold: "SOLD",
    },

    {
      img: IMG35,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `84" x 60"inch`,
      sold: "SOLD",
    },

    {
      img: IMG36,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `96" x 48"inch`,
      sold: "SOLD",
    },
    {
      img: IMG37,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `120" x 72"inch`,
      sold: "",
    },
    {
      img: IMG38,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `36" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG39,
      title: "image title 4",
      painting: "Acrylic on Canvas",
      size: `72" x 36"inch`,
      sold: "",
    },
 

  ];

  return (
    <Fragment>
      <Hero img={GIF} />
      <div className="bg-zinc-900">
        <hr className=" mb-5  border-red-500" />

        <div className="container mx-auto md:px-28 pt-5">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline">
            <span className="mt-1 mr-3 text-gray-300">
              <RiGalleryFill />
            </span>
            Gallery
          </h2>
        </div>
        <Galleryimg passData={ImgData} />
      </div>
    </Fragment>
  );
};

export default Index;
